import React from 'react'
import FormContainer from '../../components/FormContainer/FormContainer'
import { useLocalization } from '../../helpers/hooks'
import Logo from '../../components/Logo'
import styled from 'styled-components'

const Expired = () => {
  const L = useLocalization()
  return (
      <FormContainer>
        <InfoContainer>
          <Body>
            <Logo/>
            <Message>
              <Span>{L('expired.title')}</Span>
            </Message>
          </Body>
        </InfoContainer>
      </FormContainer>
  )
}

export default Expired

const InfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Span = styled.span`
  text-align: center;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: red;
  font-size: 24px;
  margin-top: 40px;
`
