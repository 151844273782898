import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Loader from '../../ui/atoms/loader/loader'
import { useRouter } from 'next/router'
import { selectNavigator } from '../../redux/selectors/session'
import Unavailable from '../../features/unavailable'
import Expired from '../../features/expired'
import LoaderContainer from '../../components/LoaderContainer'
import { RootState } from '../../redux/reducers/rootReducer'

const transactionRoute = (Component) =>
  function DisplayComponent({ ...props }) {
    const { session_guid, options, active } = useSelector((state: RootState) => state.session)
    const [ready, setReady] = useState(false)
    const { paths, step } = useSelector(selectNavigator)
    const router = useRouter()

    useEffect(() => {
      if (step && !router.pathname.includes(step)) {
        router.replace(paths[step]())
      }
    }, [])

    useEffect(() => {
      if (session_guid && !ready) setReady(true)
    }, [session_guid])

    if (!ready) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )
    }

    if (!options.merchant_enabled) {
      return <Unavailable />
    }

    if (!active) {
      return <Expired />
    }

    return <Component {...props} />
  }

export default transactionRoute

