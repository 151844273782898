import React from 'react'
import { NextPage } from 'next'
import Loader from '../../ui/atoms/loader/loader'
import FormContainer from '../../components/FormContainer/FormContainer'
import transactionRoute from '../../features/transactionRoute'
import LoaderContainer from '../../components/LoaderContainer'

const Form: NextPage = () => {
  return (
    <FormContainer>
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </FormContainer>
  )
}

export default transactionRoute(Form)
